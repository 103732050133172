// eslint-disable-next-line no-redeclare,no-unused-vars
function buildTable(cont, param = {}, ids = [], sum = {}) {
  var element;
  var object = param.object;
  var objectSub = param.objectSub;
  var view = param.view;
  var max = 0;
  var sums = {};
  var instantClick;
  var amount = 0;
  var footerColspan = 1;
  var notClickableCheck = getParamSettings(param, 'notClickable');
  var tableEdit = getParamSettings(param, 'tableEdit');
  var shortKey = getParamSettings(param, 'short', null, true, true);
  var identGroup = param.identGroup;
  if (!identGroup)
    identGroup = [
      object,
      objectSub,
      param.refObj,
      param.refObjSub,
      param.refKey,
    ]
      .filter(Boolean)
      .join('_');

  // set table fields
  var tableFields = null;
  if (param.overview)
    tableFields = localStorage.getItem(identGroup + '_tableFields');
  if (!tableFields) tableFields = param.tableFields;
  if (!tableFields)
    tableFields = getParamSettings(param, 'tableFields', null, null, true);
  if (isString(tableFields)) tableFields = tableFields.split(',');
  else if (!tableFields) tableFields = ['editStamp', 'createStamp', 'owner'];
  var tableFieldsSmall = getParamSettings(param, 'tableFieldsSmall');
  if (!tableFieldsSmall)
    tableFieldsSmall = ['status', 'warning', shortKey, 'chat'];

  // table attributes
  var table = {};
  table.where = {}; // original where filter
  table.filter = {}; // filter for requests
  table.filterJs = {}; // filter for js checks
  table.identGroup = identGroup;
  if (param.where) table.where = param.where;
  // else if (param.send && param.send.where) table.where = param.send.where;
  if (param.refTable) table.refTable = true;
  if (param.search) table.search = param.search;
  if (param.sort && !param.sortable) table.sort = param.sort;
  else if (!param.sortable) table.sort = getParamSettings(param, 'sort');
  table.color = getParamSettings(param, 'color');
  table.noColor = getParamSettings(param, 'noColor');
  table.selectedAll;
  table.selectedLeave;
  table.ident = param.ident;
  table.created = 0;
  table.createStamp = moment().format('DD.MM.YYYY HH:mm:ss');
  table.ids = [];
  table.timeouts = [];
  if (param.directData) table.tr = [];
  else table.tr = {};
  table.tableFields = arrayMerge([], tableFields);
  if (!param.directData && inArray(shortKey, tableFieldsSmall))
    table.tableFields.splice(table.tableFields.length - 1, 0, shortKey);
  if (view) view.table = table;
  param.table = table;

  // create
  var create = function (param2 = {}) {
    if (param.addSearch) addSearchBar(cont, param);
    else if (object && !param.overview && !param.noHeader)
      contextMenu(cont, {searchBar: 'Tabellensuche anzeigen'}, function (type) {
        if (type == 'searchBar' && !table.searchBar)
          addSearchBar(contTable, param);
      });

    var contTable = $('<div></div>').appendTo(cont);
    element = $(
      '<table class="table table-striped table-hover" ident="' +
        table.ident +
        '"></table>'
    ).appendTo(contTable);
    element.table = table;
    table.element = element;

    table.page = 0;
    if (getParamSettings(param, 'grid')) element.addClass('grid');
    if (getParamSettings(param, 'noWrap')) element.addClass('text-nowrap');
    if (!param.noHeader) table.buildHeader();
    element.tbody = $('<tbody></tbody>').appendTo(element);
    if (!param.noFooter && !param.refTable) table.buildFooter();
    if (!param.noRegister && !param2.noRegister)
      registerTable(table, object, param);
    else table.nonRegistered = true;
    table.buildScroller(contTable);
    if (!amount && !param.visible) contTable.addClass('d-none');
  };

  // table header
  table.buildHeader = function () {
    footerColspan = 1;
    var thead = $('<thead></thead>').appendTo(element);
    element.thead = $('<tr></tr>').appendTo(thead);
    if (getParamSettings(param, 'tableHeader') == 'vertical') {
      element.thead.vertical = true;
      thead.addClass('vertical');
      element.thead.height = 0;
    }
    var tableLabels = getParamSettings(param, 'tableLabels');
    var validLabels = false;
    $.each(table.tableFields, function (i, key) {
      if (!key) return;
      var settings = {};
      if (getObjectValue(fields, [object, key]))
        settings = getFieldSettings(param, key);
      else if (str_contains(key, '.')) settings = getRefSettings(key, object);
      else if (param.fields && param.fields[key]) settings = param.fields[key];
      else if (param.pivotTable && str_ends_with(key, '_pivot'))
        settings = fields[param.pivotTable][key.replace('_pivot', '')];
      if (str_ends_with(key, '_percent'))
        settings = Object.assign(
          {},
          fields[object][key.replace('_percent', '')],
          {entity: '%'}
        );
      if (isHidden(settings, null, param)) return;

      // get header label
      var label = '';
      if (tableLabels && tableLabels[key]) label = tableLabels[key];
      else if (settings.labelShort) label = settings.labelShort;
      // else if (settings.icon) label = '<i class="icon fa-regular fa-' + settings.icon + ' fa-fw"></i>';
      else if (settings.label) label = settings.label;
      else if (param.labels) label = param.labels[i];
      else if (key == 'amount') label = 'Menge';
      else if (str_starts_with(key, 'short')) label = 'Bezeichnung';
      else if (param.edit && param.edit[key]) label = param.edit[key].label;

      // get label entity
      if (settings.entity) {
        var entity = settings.entity;
        if (str_starts_with(entity, param.refObj + '.'))
          entity = entity.slice(strpos(entity, '.') + 1);
        entity = calculate(entity, null, param.refRow, {
          object: param.refObj,
          currency: true,
        });
        label += '&nbsp;(' + entity + ')';
      }

      if (!validLabels && label) validLabels = true;
      var th = $('<th key="' + key + '"></th>').appendTo(element.thead);
      var thLabel = $('<span>' + label + '</span>').appendTo(th);
      if (settings.label && (settings.labelShort || settings.icon))
        th.addTooltip(settings.label);
      if (settings.widthMin) th.css('min-width', settings.widthMin + 'px');
      const width = localStorage.getItem(table.ident + '_' + key + '_width');
      if (width) th.width(width);

      // sort option
      if (
        !param.directData &&
        !optionValue({
          settings: parameter[object],
          key: 'noSort',
          objectSub: objectSub,
        }) &&
        !str_contains(key, '.') &&
        !param.sortable
      )
        th.addClass('link').on(click, function () {
          if (table.sort == key) table.sort = '-' + table.sort;
          else table.sort = key;
          table.loadData({refresh: true});
          element.thead.find('th:not(.select) .iconSort').remove();
          th.sortIcon();
        });
      th.sortIcon = function () {
        if (key == table.sort || inArray(key, table.sort))
          thLabel.append('<i class="icon fa-regular fa-sort-up iconSort"></i>');
        else if ('-' + key == table.sort || inArray('-' + key, table.sort))
          thLabel.append(
            '<i class="icon fa-regular fa-sort-down iconSort"></i>'
          );
      };
      if (table.sort) th.sortIcon();

      if (test && user.admin)
        $('<span class="admin-info px-2">' + key + '</span>').prependTo(th);
      if (element.thead.vertical) {
        th.html('<span>' + label + '</span>');
        var height = $('#widthHelper').text(label).width();
        if (height > element.thead.height) element.thead.height = height;
      }

      // add table customization
      if (!param.sortable)
        requestIdleCallback(function () {
          var loadDefault = function () {
            localStorage.removeItem(identGroup + '_tableFields');
            if (param.tableFields) tableFields = param.tableFields;
            else
              tableFields = getParamSettings(
                param,
                'tableFields',
                null,
                null,
                true
              );
            table.tableFields = tableFields;
            table.update();
            view.tableInfo.remove();
            delete view.tableInfo;
          };

          var context = {};
          if (table.searchKeys && inArray(key, table.searchKeys))
            context.search = 'Tabelle nach ' + label + ' filtern';
          context.edit = 'Spaltenmanager';
          if (
            param.overview &&
            localStorage.getItem(identGroup + '_tableFields')
          ) {
            context.default = 'Spaltenanordnung Standard wiederherstellen';
            if (!view.tableInfo || view.tableInfo.is(':hidden')) {
              view.tableInfo = $(
                '<div class="text-end">* Spaltenanordnung</div>'
              )
                .appendTo(view.body)
                .addTooltip('Individuelle Spaltenanordnung lokal gespeichert');
              contextMenu(
                view.tableInfo,
                {default: context.default},
                function (type) {
                  if (type == 'default') loadDefault();
                }
              );
            }
          }
          if (admin && test && config) {
            context.saveColumns = 'Spalten als Standard abspeichern';
            context.saveSort = 'Sortierung als Standard abspeichern';
          }
          contextMenu(th, context, function (type) {
            if (type == 'search') table.selectFilter.add(key);
            else if (type == 'edit') table.editColumns(key);
            else if (type == 'saveColumns') {
              saveSettings({
                object: object,
                objectSub: objectSub,
                key: 'tableFields',
                value: table.tableFields,
              });
            } else if (type == 'saveSort') {
              saveSettings({
                object: object,
                objectSub: objectSub,
                key: 'sort',
                value: table.sort,
              });
            } else if (type == 'default') {
              loadDefault();
            }
          });
        });

      if (
        settings.number &&
        !settings.labels &&
        !settings.reference &&
        settings.field != 'checkbox'
      )
        th.addClass('text-end');
      else if (settings.reference && settings.multi && !settings.convertTable)
        th.addClass('text-end');
      else if (settings.fieldTable == 'colorbox') th.addClass('text-center');

      if (inArray(key, tableFieldsSmall)) {
        th.addClass('table-small-show');
        if (key != shortKey) th.addClass('table-big-show');
      }

      // width resize
      th.resizable({
        handles: 'e',
        stop: function () {
          localStorage.setItem(
            table.ident + '_' + key + '_width',
            $(this).width()
          );
        },
      });
    });
    if (!validLabels) thead.remove();
    if (element.thead.vertical)
      element.thead.children('th').height(element.thead.height);

    // top select
    if (!param.noSelect) {
      footerColspan++;
      var th = $('<th class="row-select" scope="row"></th>').prependTo(
        element.thead
      );
      var div = $('<div class="form-check"></div>')
        .appendTo(th)
        .on(click, function () {
          $(this).children('input').trigger(click);
        });
      element.thead.select = $(
        '<input class="form-check-input" type="checkbox" />'
      )
        .prependTo(div)
        .on(click, function (evt) {
          if ($(this).is(':checked')) table.selectAll();
          else table.deselectAll();
          evt.stopPropagation();
        });
    }
  };

  // selectors
  table.selectAll = function () {
    table.selectedAll = true;
    element.tbody
      .children()
      .addClass('table-active')
      .find('.row-select .form-check-input')
      .prop('checked', true);
    if (element.thead)
      element.thead.find('.form-check-input').prop('checked', true);
  };
  table.deselectAll = function () {
    table.selectedAll = false;
    element.tbody
      .children()
      .removeClass('table-active')
      .find('.row-select .form-check-input')
      .prop('checked', false);
    if (element.thead)
      element.thead.find('.form-check-input').prop('checked', false);
  };
  table.selectCheck = function () {
    if (
      table.selectedAll &&
      element.tbody.find('.form-check-input:not(:checked)')[0]
    ) {
      table.selectedAll = false;
      element.thead.find('.form-check-input').prop('checked', false);
    }
    // if (param.noSelect || table.ids.length > 100) return;
    // var selected = element.tbody.children('.table-active').length;
    // if (table.ids.length == selected) table.selectedAll = true;
    // else table.selectedAll = false;
    // if (element.thead) {
    // 	if (table.selectedAll) element.thead.select.children('svg').addClass('fa-check-square').removeClass('fa-square').removeClass('fa-minus-square');
    // 	else if (selected) element.thead.select.children('svg').addClass('fa-minus-square').removeClass('fa-square').removeClass('fa-check-square');
    // 	else element.thead.select.children('svg').addClass('fa-square').removeClass('fa-minus-square').removeClass('fa-check-square');
    // }
  };
  table.selected = function () {
    if (table.selectedAll) return table.ids;
    else
      return element.tbody
        .children('.table-active')
        .map(function () {
          return parseInt($(this).attr('data-id'));
        })
        .get();
  };
  table.selectRow = function (id) {
    element.tbody
      .find('[data-id=' + id + ']')
      .addClass('active')
      .addClass('table-active');
    table.selectCheck();
  };
  table.filtered = function (filter, noSort) {
    var selected = table.selected();
    if (
      table.selectedAll &&
      param.overview &&
      !table.search &&
      !table.refTable &&
      !param.idData &&
      selected.length >= 100
    ) {
      filter.all = true;
      filter.where = table.filter;
    } else if (selected.length) filter.ids = selected;
    else return false;
    if (table.sort && !noSort) filter.sort = table.sort;
  };

  // getters
  table.getAmount = function () {
    return amount;
  };

  // table footer
  table.buildFooter = function () {
    if (element.tfoot) element.tfoot.remove();
    element.tfoot = $('<tfoot></tfoot>').appendTo(element);
    var contTr = $('<tr></tr>').appendTo(element.tfoot);
    var contAmount = $(
      '<th class="table-small-show table-big-show" colspan="' +
        footerColspan +
        '">Anzahl: </th>'
    ).appendTo(contTr);
    table.amount = $(
      '<span>' + number_format(amount, 0, ',', '.') + '</span>'
    ).appendTo(contAmount);

    // save to kpi
    contextMenu(
      table.amount,
      {kpi: 'Anzahl zum Dashboard hinzufügen (KPI)'},
      function (type) {
        if (type == 'kpi') {
          var ident = [object, objectSub, Math.abs(checksum(table.filter))]
            .filter(Boolean)
            .join('-');
          var label = prompt('Bezeichnung', view.title);
          if (!label) return;
          fields.start.kpi[ident] = {
            label: label,
            object: object,
            objectSub: objectSub,
            where: table.filter,
            key: 'id',
          };
          saveSettings(
            {
              object: 'start',
              key: 'kpi',
              sub: ident,
              value: fields.start.kpi[ident],
            },
            function () {
              if (!isArray(fields.start.kpi.keys))
                fields.start.kpi.keys = fields.start.kpi.keys.split(',');
              if (!inArray(ident, fields.start.kpi.keys))
                fields.start.kpi.keys.push(ident);
              saveSettings(
                {
                  object: 'start',
                  key: 'kpi',
                  sub: 'keys',
                  value: fields.start.kpi.keys,
                },
                function () {
                  dashboard.update();
                }
              );
            }
          );
        }
      }
    );

    $.each(table.tableFields, function (j, key) {
      if (!j) return;
      var settings = getObjectValue(fields, [object, key]);
      if (isHidden(settings, null, param)) return;
      var th = $('<th key="' + key + '"></th>').appendTo(contTr);
      if (sum && isset(sum[key]) && j)
        th.text(
          convert({
            value: sum[key],
            settings: settings,
            sum: true,
            read: true,
          })
        );
      if (
        (settings.number &&
          !settings.labels &&
          !settings.reference &&
          !settings.editField &&
          settings.field != 'checkbox') ||
        (settings.field == 'table' && !settings.convertTable)
      )
        th.addClass('text-end');
      if (inArray(key, tableFieldsSmall)) {
        th.addClass('table-small-show');
        if (key != shortKey) th.addClass('table-big-show');
      }

      // save to kpi
      if (sum && isset(sum[key]))
        contextMenu(
          th,
          {kpi: 'Wert zum Dashboard hinzufügen (KPI)'},
          function (type) {
            if (type == 'kpi') {
              var ident = object + ucfirst(key);
              var label = prompt('Bezeichnung', settings.label);
              if (!label) return;
              fields.start.kpi[ident] = {
                label: label,
                object: object,
                objectSub: objectSub,
                where: table.where,
                key: key,
              };
              saveSettings(
                {
                  object: 'start',
                  key: 'kpi',
                  sub: ident,
                  value: fields.start.kpi[ident],
                },
                function () {
                  if (!inArray(ident, fields.start.kpi.keys))
                    fields.start.kpi.keys.push(ident);
                  saveSettings(
                    {
                      object: 'start',
                      key: 'kpi',
                      sub: 'keys',
                      value: fields.start.kpi.keys,
                    },
                    function () {
                      dashboard.update();
                    }
                  );
                }
              );
            }
          }
        );
    });
  };

  table.sortLast = function () {
    element.tbody
      .find('tr[data-last]')
      .removeAttr('data-sort')
      .each(function () {
        $(this).removeAttr('data-last');
        var id = parseInt($(this).attr('data-id'));
        var row = getData(object, id);
        table.sorter(row, true, true);
      });
  };

  // table change
  table.loadData = function (param2 = {}) {
    element.loading = true;
    if (param2.objectSub) objectSub = param2.objectSub;
    cont.find('.searchBar').addClass('disabled');

    var send = {table: true, where: table.where};
    if (table.filter) send.where = table.filter;
    if (param.idData) {
      if (table.idsFull && table.idsFull.length) send.where.id = table.idsFull;
      else send.where.id = table.ids;
    }
    if (param.where) send.where = Object.assign({}, param.where, send.where);
    if (param2.update) send.update = true;
    if (table.search) send.search = table.search;
    if (table.sort) send.sort = table.sort;
    if (table.tableFields) send.tableFields = table.tableFields;
    if (max) send.max = max;
    if (param2.page) send.page = param2.page;
    if (getObjectValue(param, ['send', 'refKey'])) {
      send.refObj = param.send.refObj;
      send.refKey = param.send.refKey;
      send.refId = param.send.refId;
    }
    if (param.refKey) send.noTotal = true;
    ajax({
      object: object,
      objectSub: objectSub,
      send: send,
      callback: function (xhr) {
        cont.find('.searchBar').removeClass('disabled');
        table.addData({
          data: xhr.ids,
          amount: xhr.amount,
          emptyTable: param2.refresh,
        });
        sum = xhr.sum;
        if (!param.noFooter) table.buildFooter();
        table.selectCheck();
        table.sortLast();
      },
    });
    if (table.timeouts.length)
      $.each(table.timeouts, function (i, timeout) {
        clearTimeout(timeout);
      });
  };

  // fill table
  table.addData = function (param2) {
    if (param2.emptyTable) table.empty();
    if (param2.sum) sum = param2.sum;
    if (param2.amount) {
      amount = param2.amount;
      if (table.amount) table.amount.text(number_format(amount, 0, ',', '.'));
    }

    $.each(param2.data, function (i, row) {
      if (!isPlainObject(row)) row = getData(object, row);
      row.i = i;
      table.add(row, null, param2.amount);

      if (param.jumpTo && checkWhere(row, param.jumpTo))
        requestIdleCallback(function () {
          element.table.tr[row.id].jumpTo();
        });
    });
    element.loading = false;

    // mark search results
    if (table.search)
      requestIdleCallback(function () {
        table.search.split(' ').forEach(function (value) {
          if (value.length > 1)
            element.tbody
              .find('td span:icontains("' + value + '")')
              .each(function () {
                $(this).html(
                  $(this)
                    .html()
                    .replace(
                      new RegExp('(' + value + ')', 'ig'),
                      '<mark>$1</mark>'
                    )
                );
              });
        });
      });

    // sortable
    if (param.sortable)
      element.tbody.sortable({
        cursor: 'move',
        helper: 'clone',
        update: function () {
          table.ids = element.tbody
            .children('[data-id]')
            .map(function () {
              var id = $(this).attr('data-id');
              if (isNumeric(id)) id = parseInt(id);
              return id;
            })
            .get();
          param.element.save();
        },
      });

    // footer on top
    if (param.overview && user.tableSumTop)
      requestIdleCallback(function () {
        element.find('.sumLine').remove();
        var height = element.thead.height() - 3;
        var sumLine = element.tfoot
          .children('tr')
          .clone(true)
          .insertAfter(element.thead)
          .addClass('sumLine');
        sumLine.children('th').css('top', height);
      });
  };

  let mainTopic = null;
  let subTopic = null;

  // add new data
  table.add = function (row, paramNew, noAmount) {
    if (table.max && table.ids.length >= table.max) return;
    else if (row.disabled && param.idData) return;
    if (!row.id && param.directData && !param.object && isset(row.i))
      row.id = parseInt(row.i);
    if (!row.id && param.idData) return;
    if (isset(row.id)) {
      if (inArray(row.id, table.ids)) return;
      else table.ids.push(row.id);
    }

    var timeout = setTimeout(function () {
      table.addToTable(row, paramNew, noAmount);
    }, 1);
    table.timeouts.push(timeout);
  };

  // add new data
  table.addToTable = function (row, paramNew, noAmount) {
    // wiki
    let isSubTopic = false;
    if (param.object === 'wiki') {
      let countSubTopics = row.related?.length;
      if (countSubTopics == undefined) countSubTopics = 0;
      if (row.related && mainTopic && subTopic) {
        if (row.related.includes(mainTopic) || subTopic.includes(row.related)) {
          isSubTopic = true;
        } else {
          mainTopic = row.id;
          subTopic = null;
        }
      } else {
        mainTopic = row.id;
        subTopic = row.related;
      }
    }

    if (element.is(':hidden')) element.parent().removeClass('d-none');
    var tr = $('<tr class="table-row"></tr>').appendTo(element.tbody);
    if (row.disabled) tr.addClass('text-decoration-line-through');
    if (row.border) tr.addClass('border');

    if (param.directData) table.tr.push(tr);
    else if (row.id) table.tr[row.id] = tr;
    if (isset(row.id)) tr.attr('data-id', row.id);
    register(tr, object, row.id, 'disabled');

    var notClickable = false;
    if (
      notClickableCheck &&
      (notClickableCheck == 1 ||
        checkWhere(row, notClickableCheck, {object: object}))
    )
      notClickable = true;

    // if (paramNew && paramNew.event && param.notification) sendNotification({title: param.title, message: getRow(row, 'short', param)});
    if (table.sort) {
      if (paramNew)
        setTimeout(function () {
          table.sorter(row, paramNew.event);
        }, 100);
      else tr.attr('data-sort', table.sortId(row));
    }

    // background color
    if (!table.color && !table.noColor) {
      if (row.color) tr.addClass('table-' + row.color);
      register(tr, object, row.id, 'color');
    }

    // unseen
    if (
      optionValue({
        settings: fields[object],
        objectSub: objectSub,
        key: 'unseen',
      })
    ) {
      if (row.unseen) tr.addClass('fw-bold');
      register(tr, object, row.id, 'unseen');
    }

    // amount
    if ((param.idData || param.directData || paramNew) && !noAmount) {
      amount++;
      if (table.amount) table.amount.text(number_format(amount, 0, ',', '.'));
    }

    // change from dataset
    tr.val = function (value, object, id, key) {
      if (key == 'disabled') {
        if (value) tr.addClass('text-decoration-line-through');
        else tr.removeClass('text-decoration-line-through');
      } else if (key == 'color')
        tr.removeClassStartingWith('table-color').addClass('table-' + value);
      else if (key == 'unseen') {
        var row = getData(object, id);
        if (row.unseen) tr.addClass('fw-bold');
        else tr.removeClass('fw-bold');
      }
    };

    // fill tr
    if (paramNew) {
      if (getObjectValue(cont, ['sub', 'id']) == row.id)
        tr.prependTo(element.tbody);
    }

    // select
    if (!param.noSelect) {
      var td = $('<td class="row-select" scope="row"></td>').prependTo(tr);
      var div = $('<div class="form-check"></div>')
        .appendTo(td)
        .on(click, function () {
          $(this).children('input').trigger(click);
        });
      var select = $('<input class="form-check-input" type="checkbox" />')
        .appendTo(div)
        .on(click, function (evt) {
          if (evt.shiftKey) tr.selectMulti();
          else if ($(this).is(':checked')) tr.selectRow();
          else tr.deselectRow();
          evt.stopPropagation();
        });
      if (table.selectedAll) select.prop('checked', true);
    }
    tr.selectRow = function () {
      if (select) select.prop('checked', true);
      $(this).addClass('table-active');
      table.selectCheck();
    };
    tr.selectMulti = function () {
      tr.prevUntil('.table-active')
        .addBack()
        .addClass('table-active')
        .find('.form-check-input')
        .prop('checked', true);
      table.selectCheck();
    };
    tr.deselectRow = function () {
      if (select) select.prop('checked', false);
      $(this).removeClass('table-active');
      table.selectCheck();
    };
    tr.activate = function () {
      tr.addClass('active').siblings().removeClass('active');
      tr.selectRow();
    };

    // open detail
    tr.openDetail = function (key, context, showAsView) {
      if (tr.activate) tr.activate();
      if (param.picker && !context) {
        param.picker([row.id], view);
        return;
      }
      if (strpos(row.id, '_') !== false)
        row.id = row.id.slice(0, strpos(row.id, '_'));
      var pm = {
        func: 'detail',
        object: object,
        objectSub: objectSub,
        id: row.id,
      };
      if (showAsView) pm.thinView = true;
      else if (view && (view.detail || view.modal)) pm.modal = true;
      // if (key) pm.active = key;
      if (param.keepOverview) pm.keepOverview = true;
      if (param.noOverview) pm.noOverview = true;
      if (instantClick) {
        pm.instantClick = instantClick;
        instantClick = false;
      }
      if (tr.prev().is('tr'))
        pm.prev = function (sub) {
          if (sub.modal) sub.close();
          if (sub.sub && sub.lastClick) instantClick = sub.lastClick;
          tr.prev()
            .children('[key=' + key + ']')
            .trigger(click);
        };
      if (tr.siblings('tr').length > 1)
        pm.next = function (sub) {
          if (sub.modal) sub.close();
          if (sub.sub && sub.lastClick) instantClick = sub.lastClick;
          if (tr.next().is('tr'))
            tr.next()
              .children('[key=' + key + ']')
              .trigger(click);
          else
            tr.prevAll(':last')
              .children('[key=' + key + ']')
              .trigger(click);
        };
      pm.afterClose = function () {
        tr.removeClass('active');
      };
      if (param.detailReady) {
        detail(pm);
        return;
      } else ajax(pm);
    };

    tr.jumpTo = function () {
      var top = tr[0].offsetTop;
      element.parent().scrollTop(top);
      return tr;
    };

    // right click
    var contextAdd = Object.assign(
      {},
      param.contextRow,
      getParamSettings(param, 'context')
    );
    if (contextAdd)
      contextMenu(tr, contextAdd, function (type) {
        if (contextAdd[type]) {
          var settings = getFieldSettings(param, type);
          if (settings && checkSettings('addNew', settings))
            detail({
              object: settings.reference,
              objectSub: settings.objectSub,
              adopt: settings.adopt,
              ref: row,
              refObj: object,
              modal: true,
            });
          else {
            var pm = {
              object: object,
              objectSub: objectSub,
              id: row.id,
              post: true,
              send: {func: type},
            };
            if (param.refRow.id) {
              pm.send.refObj = param.refObj;
              pm.send.refId = param.refRow.id;
            }
            ajax(pm);
          }
        }
      });
    var context = {};
    if (object) {
      if (object == 'document') context.download = 'Dokument herunterladen';
      context.show = info.context.openRow;
      context.showAsView = info.context.openRowRight;
      context.showInTab = info.context.openRowTab;
    }

    if (!param.noSelect) {
      context.select = info.context.selectAll;
      context.deselect = info.context.deselectAll;
    }
    if (
      !param.noRemove &&
      !getParamSettings(param, 'noRemove') &&
      !param.read
    ) {
      if (param.directData || param.idData)
        context.remove = info.context.removeRow;
      if (object && !param.noDisable && !getParamSettings(param, 'noDisable')) {
        if (row.disabled) context.activate = info.context.activateRows;
        // if (getObjectValue(table, ['filter', 'disabled']) && role == 'boss')
        //   context.delete = info.context.deleteRows;
        context.disable = info.context.disableRows;
        if (user.admin) context.delete = info.context.deleteRows;
      }
    }
    if (object && !isRead(getParamSettings(param, 'read')) && !param.read)
      context.massEdit = info.context.editMultiRows;
    contextMenu(tr, context, function (type, target, label) {
      tr.selectRow();
      if (type == 'show') tr.openDetail(false, true);
      else if (type == 'showAsView') tr.openDetail(false, true, true);
      else if (type == 'showInTab')
        window.open(
          document.location.origin +
            '/' +
            object +
            (objectSub ? '+' + objectSub : '') +
            ',' +
            row.id
        );
      else if (type == 'select') table.selectAll();
      else if (type == 'deselect') table.deselectAll();
      else if (type == 'massEdit') table.massEdit();
      else if (type == 'download') download(row);
      else if (type == 'activate') {
        var pm = {
          object: object,
          objectSub: objectSub,
          post: true,
          view: view,
          send: {func: 'bulk', disabled: 0},
        };
        table.filtered(pm.send);
        ajax(pm);
        tr.removeClass('text-decoration-line-through');
      } else if (type == 'disable' || type == 'delete' || type == 'remove')
        table.remove(type, label);
    });

    // cells
    $.each(table.tableFields, function (j, key) {
      if (!key) return;
      if (row[key + '_id'])
        row = $.extend(true, {}, getData(object, row[key + '_id']), {
          id: row[key + '_id'],
        }); // field from other dataset
      var value = row[key];
      if (row[key + '_table']) value = row[key + '_table'];
      var settings = {};
      if (getObjectValue(fields, [object, key]))
        settings = getFieldSettings(param, key);
      else if (strpos(key, '.') !== false) {
        settings = getRefSettings(key, object);
        value = dataRef(key, object, row);
      } else if (param.fields && isPlainObject(param.fields))
        settings = param.fields[key];
      else if (param.pivotTable && str_ends_with(key, '_pivot'))
        settings = fields[param.pivotTable][key.replace('_pivot', '')];
      else if (str_ends_with(key, '_percent'))
        settings = fields[object][key.replace('_percent', '')];
      if (isHidden(settings, row, param)) return;

      // var ident = object + '_' + row.id + '_' + key + '_td';
      // var td = $('<td id="' + ident + '" key="' + key + '"></td>').appendTo(tr);
      var td = $('<td key="' + key + '"></td>').appendTo(tr);
      // if (settings.class) td.addClass(settings.class);
      if (settings.classTable && value) tr.addClass(settings.classTable);
      if (param.noHeader && settings.widthMin && !table.created)
        td.css('min-width', settings.widthMin + 'px');
      var read = false;
      if (
        param.read ||
        row.read ||
        row[key + '_read'] ||
        settings.field == 'read'
      )
        read = true; // eslint-disable-line no-unused-vars

      // edit field
      var edit = false;
      if (param.edit && !param.read) {
        if (isPlainObject(param.edit)) edit = param.edit[key] ? true : false;
        else if (isArray(param.edit))
          edit = inArray(key, param.edit) ? true : false;
        else edit = true;
        if (param.editEmpty && edit && value) edit = false;
      } else if (tableEdit && inArray(key, tableEdit)) edit = true;
      if (edit) {
        td.addClass('edit');
        if (
          settings.number &&
          !settings.labels &&
          !settings.reference &&
          settings.field != 'checkbox'
        )
          element.thead.find('th[key=' + key + ']').removeClass('text-end');
        var pm = {
          id: row.id,
          table: true,
          view: cont,
          labelHide: true,
          value: value,
          settingsNew: settings,
          classLine: 'form-group-table',
        };
        if (object) {
          pm.object = object;
          pm.objectSub = objectSub;
        }
        if (param.pivotTable)
          pm.beforeSave = function (send) {
            send[param.refObj + '_pivot'] = param.refRow.id;
            send[param.object + '_pivot'] = row.id;
          };
        if (isPlainObject(param.edit) && param.edit[key])
          Object.assign(settings, param.edit[key]);
        else pm.noEntity = true;
        if (param.directData && settings.calculate)
          pm.save = function (callback, send, noDataController) {
            $.each(settings.calculate, function (k, v) {
              var row2 = $.extend(true, {}, param.refRow);
              row2[key] = send.val();
              var result = calculate(v, settings, row2);
              tr.els[k].val(result);
            });
            param.save(callback, send, noDataController);
          };
        else if (param.save) pm.save = param.save;

        if (!tr.els) tr.els = {};
        var line = buildFormLine(td, pm, key);
        tr.els[key] = line.element;
      }

      // direct data
      else if (param.fields) {
        settings.field = 'read';
        if (settings.entity) delete settings.entity;
        pm = {
          id: row.id,
          table: true,
          view: cont,
          labelHide: true,
          value: value,
          settingsNew: settings,
          classLine: 'form-group-table',
        };
        if (object) {
          pm.object = object;
          pm.objectSub = objectSub;
        }
        var line2 = buildFormLine(td, pm, key);
        if (settings.widthMax) line2.css('max-width', settings.widthMax + 'px');

        // add history functions
        if (param.refKey == 'history') {
          var context = {restore: info.context.restoreOld};
          context.restoreNew = info.context.restoreNew;
          if (user.admin) context.changeStamp = info.context.changeStamp;
          contextMenu(td, context, function (type) {
            if (type == 'restore' || type == 'restoreNew') {
              var save = {};
              var valType = 'old';
              if (type == 'restoreNew') valType = 'new';
              if (row[valType + 'Val']) save[row.key] = row[valType + 'Val'];
              else
                save[row.key] = convertBack(
                  row[valType],
                  fields[param.refObj][row.key]
                );
              ajax({
                object: param.refObj,
                id: param.refRow.id,
                post: true,
                send: save,
              });
            } else if (type == 'changeStamp') {
              var val = prompt(info.context.newValue, row.stamp);
              if (val) {
                save = {};
                setObjectValue(save, ['history', row.i, 'stamp'], val);
                ajax({
                  object: param.refObj,
                  id: param.refRow.id,
                  post: true,
                  send: save,
                });
                td.text(val);
              }
            }
          });
        }
      } else if (param.directData) {
        td.html(value);
        if (param.element && !param.object && !param.read)
          contextMenu(td, {edit: info.context.changeValue}, function (type) {
            if (type == 'edit') {
              var result = prompt(info.context.newValue, td.text());
              if (isset(result)) {
                td.text(result).addClass('changed');
                param.element.save();
              }
            }
          });

        td.val = function () {
          return $(this).html();
        };
        if (!tr.els) tr.els = {};
        tr.els[key] = td;
      }

      // normal reference field
      else if (object) {
        if (!param.noLink && !notClickable)
          td.addClass('link').on(click, function () {
            table.deselectAll();
            if (param.detailCallback) param.detailCallback(row);
            else tr.openDetail(key);
          });
        td.val = function (val, update, id) {
          if (defined(val)) {
            var text = val;
            if (!id) row[key] = val;
            if (sum && isset(sum[key]) && update) {
              sum[key] -= sums[key + row.id] - val;
              if (element.tfoot)
                element.tfoot.find('[key=' + key + ']').text(
                  convert({
                    value: sum[key],
                    settings: settings,
                    row: row,
                    key: key,
                    object: object,
                  })
                );
            }
            if (sum && isset(sum[key])) sums[key + row.id] = val;
            if (
              val &&
              settings.reference &&
              !settings.refTable &&
              !settings.refValue &&
              (!isArray(val) || settings.convertTable)
            ) {
              var short = getParamSettings(settings, 'short', null, true, true);
              register(td, settings.reference, val, short);
            }

            // skip disabled row
            if (
              val &&
              Number.isInteger(val) &&
              settings.reference &&
              getObjectValue(data, [settings.reference, val, 'disabled'])
            )
              return;

            if (key != 'short' && !settings.noConvert)
              text = convert({
                value: val,
                settings: settings,
                row: row,
                object: object,
                objectSub: objectSub,
                key: key,
                table: true,
                overview: param.overview,
              });
            if (!text && isString(val)) text = val;
            else if (isArray(text)) text = text.join(', ');

            // wiki todo
            if (param.object === 'wiki' && isSubTopic && key === 'short') {
              text = `<span style="margin-left: 2em;">${text}</span>`;
            }
            if (param.object === 'wiki' && key === 'short') {
              td.attr('colspan', 2);
            }

            if (text && isString(text) && !str_starts_with(text, '<'))
              text = '<span>' + text + '</span>';
            td.html(text);
            td.children('[tooltip]').each(function () {
              $(this).addTooltip($(this).attr('tooltip'));
            });

            if (td.children('img')[0])
              td.addClass('image').children('img').showImagePreview();
            else if (td.children('.pdf')[0])
              td.children('.pdf').showPdfPreview();
            // else if (settings.link && val) td.off(click).on(click, function () {
            // 	if (settings.link != '1') {
            // 		var pm = {text: settings.link, row: row, object: object};
            // 		if (strpos(settings.link, 'http') === 0 && strpos(settings.link, '__') !== 0) pm.encode = true;
            // 		val = convertPattern(pm);
            // 	}
            // 	window.open(val);
            // });
            else if (
              settings.number &&
              settings.reference == 'document' &&
              !settings.multi &&
              val
            ) {
              var refRow = getData(settings.reference, val);
              if (refRow.type == 'pdf') td.addPdfTooltip(refRow.file);
              td.off(click).on(click, function () {
                buildFilePopup({id: val});
              });
            }
            // else if (optionValue({settings: settings, key: 'link', index: 'any'}))
            // 	contextMenu(td, {link: 'Link öffnen'}, function (type) {
            // 		if (type == 'link') {
            // 			var link = optionValue({settings: settings, key: 'link', index: 'any'});
            // 			if (link == '1' && val) {
            // 				link = val;
            // 				if (isArray(link)) link = link[0];
            // 				if (strpos(link, ':') === false) link = 'http://' + link;
            // 			} else {
            // 				var pm = {text: link, row: row, object: object};
            // 				link = convertPattern(pm);
            // 			}
            // 			window.open(link, '_blank');
            // 		}
            // 	});
          } else return convertBack(td.text(), settings);

          if (
            val &&
            !param.picker &&
            checkSettings(['mail', 'button'], settings, objectSub)
          ) {
            for (var i = 2; i <= 6; i++) {
              if (
                settings['field' + i] &&
                (settings['field' + i] == 'mail' ||
                  (settings['field' + i] == 'button' &&
                    (settings['linkField' + i] ||
                      (settings.link && !settings['funcField' + i]))))
              ) {
                buildField(
                  td,
                  {
                    object: object,
                    objectSub: objectSub,
                    row: row,
                    value: val,
                    table: true,
                  },
                  key,
                  settings,
                  i
                );
              }
            }
          }
        };
        td.update = function () {
          td.html(convert({value: value, settings: settings, key: key}));
        };
        td.view = cont;
        register(td, object, row.id, key);
        td.val(value, false);
      }

      // json table or history
      else if (td.children('div,br').length) td.wrapInner('<div></div>');

      // history table
      if (param.refKey == 'history') {
        if (
          key == 'key' &&
          getObjectValue(fields, [param.refObj, value, 'label'])
        ) {
          td.text(fields[param.refObj][value].label);
        } else if (key == 'user') {
          if (row.userVal) {
            var editUser = getData('user', row.userVal);
            if (!editUser.admin) td.text(editUser.short);
            else if (user.admin) td.text(editUser.username);
          }
        } else if (key == 'stamp') {
          td.text(convert({value: value, settings: {date: true}}));
        } else if (row.newVal && key == 'new' && row.object) {
          td.addClass('link').on(click, function () {
            ajax({
              type: 'detail',
              object: row.object,
              objectSub: row.objectSub,
              id: row.newVal,
              modal: true,
            });
          });
        } else if (
          row.newVal &&
          key == 'new' &&
          getObjectValue(fields, [param.refObj, row.key, 'reference']) &&
          !getObjectValue(fields, [param.refObj, row.key, 'refTable']) &&
          !str_contains(row.newVal, ',')
        ) {
          td.addClass('link').on(click, function () {
            ajax({
              type: 'detail',
              object: fields[param.refObj][row.key].reference,
              objectSub: fields[param.refObj][row.key].objectSub,
              id: row.newVal,
              modal: true,
            });
          });
        } else if (
          row.oldVal &&
          key == 'old' &&
          getObjectValue(fields, [param.refObj, row.key, 'reference']) &&
          !getObjectValue(fields, [param.refObj, row.key, 'refTable']) &&
          !str_contains(row.oldVal, ',')
        ) {
          td.addClass('link').on(click, function () {
            ajax({
              type: 'detail',
              object: fields[param.refObj][row.key].reference,
              objectSub: fields[param.refObj][row.key].objectSub,
              id: row.oldVal,
              modal: true,
            });
          });
        }
      }

      // styling
      if (
        (settings.number &&
          !settings.labels &&
          !settings.reference &&
          !settings.editField &&
          settings.field != 'checkbox') ||
        (settings.field == 'table' && !settings.convertTable)
      )
        td.addClass('text-end');
      else if (settings.fieldTable == 'colorbox' || row[key + '_field'])
        td.addClass('text-center');

      if (inArray(key, tableFieldsSmall)) {
        td.addClass('table-small-show');
        if (key != shortKey) td.addClass('table-big-show');
      }

      // select row
      if (tr.selectRow)
        td.on('click contextmenu', function () {
          tr.selectRow();
        });
    });
    table.created++;

    // after build
    if (paramNew) {
      if (getObjectValue(cont, ['sub', 'id']) == row.id) tr.activate();
      if (paramNew.open) tr.openDetail();
    }

    return tr;
  };
  table.rem = function (row) {
    // if (getParamSettings({object: object, objectSub: objectSub}, 'noAutoUpdate')) return;
    element.tbody.find('[data-id=' + row.id + ']').remove();
    table.ids = arrayRemove(table.ids, row.id);
    var shorts = getObjectValue(elements, [object, row.id, 'short_els']);
    if (shorts)
      $.each(shorts, function (i, short) {
        if (short) short.val();
      });
  };
  // table.update = function (row, type) {
  // 	var tr = element.tbody.find('[data-id=' + row.id + ']');
  // };
  table.empty = function () {
    if (param.idData && !isset(table.idsFull)) table.idsFull = table.ids;
    element.tbody.empty();
    table.ids = [];
    table.created = 0;
    table.page = 0;
    if (param.directData) table.tr = [];
    else table.tr = {};
    element.parent().scrollTop(top);
    amount = 0;
    if (table.amount) table.amount.text(number_format(amount, 0, ',', '.'));
  };

  // body scroller + paginator
  table.buildScroller = function (cont) {
    cont.addClass('table-scroll').attr('data-mdb-perfect-scrollbar', true);
    new mdb.PerfectScrollbar(cont[0], {scrollXMarginOffset: 5});

    // paginator
    if (!param.directData && !param.idData)
      cont.on('scrollY.mdb.ps', function () {
        if (element.loading || table.ids.length >= amount) return;
        var rest =
          this.scrollHeight - Math.ceil($(this).scrollTop() + $(this).height());
        if (rest < 1000) {
          table.page++;
          table.loadData({page: table.page});
        }
      });
  };

  // remove line
  table.remove = function (type, label) {
    var sel = table.selected();
    if (!sel || !sel.length) {
      showInfo(info.pickedEmpty, 'warning');
      return;
    }
    if (getParamSettings(param, 'read')) {
      var selValid = [];
      $.each(sel, function (i, selId) {
        var row = getData(object, selId);
        if (row && !isReadMode(row, param)) selValid.push(selId);
      });
      if (selValid.length) sel = selValid;
      else return;
    }
    if (object && type != 'remove') {
      var amount2 = sel.length;
      if (table.selectedAll) amount2 = amount;
      buildConfirmPopup(
        info.removeTable
          .replace('__amount__', amount2)
          .replace('__type__', label.toLowerCase()),
        function () {
          var pm = {
            object: object,
            objectSub: objectSub,
            view: view,
            send: {},
          };
          table.filtered(pm.send, true);
          if (pm.send.all) {
            if (view.overview) pm.send.func = 'bulk';
            else if (param.idData) {
              pm.send.ids = table.ids;
              delete pm.send.all;
            }
          }
          if (type == 'disable') {
            pm.post = true;
            pm.send.disabled = 1;
            pm.send.func = 'bulk';
          } else if (type == 'delete') pm.delete = true;
          if (param.refLine && param.refLine.saveAmount)
            pm.callback = function () {
              param.refLine.saveAmount(-1);
            };
          ajax(pm);
          element.tbody.children('.table-active').remove();
          $.each(sel, function (i, id) {
            table.ids = arrayRemove(table.ids, id);
            if (elements[object][id])
              $.each(elements[object][id]['id_els'], function (i, el) {
                if (el) el.remove();
              });
            // if (viewStack[object + ',' + id]) {
            // 	viewStack[object + ',' + id].close();
            // 	delete viewStack[object + ',' + id];
            // }
          });
          if (param.refTable)
            setData(
              param.refObj,
              param.refRow.id,
              param.refKey,
              table.ids.length,
              null,
              null,
              true
            );
          else if (param.element) param.element.save();
        }
      );
    } else {
      element.tbody.children('.table-active').remove();
      $.each(sel, function (i, id) {
        table.ids = arrayRemove(table.ids, id);
      });
      if (param.refTable)
        setData(
          param.refObj,
          param.refRow.id,
          param.refKey,
          table.ids.length,
          null,
          null,
          true
        );
      else if (param.element) param.element.save();
    }

    amount -= sel.length;
    if (table.amount) table.amount.text(number_format(amount, 0, ',', '.'));
  };

  // mass edit
  table.massEdit = function () {
    var selected = table.selected();
    if (selected.length < 2) {
      showInfo(info.pickedEmpty, 'warning');
      return;
    }
    data[object].bulk = {id: 'bulk'};
    var title =
      'Massenbearbeitung: ' +
      selected.length +
      ' ' +
      getParamSettings(param, 'label');
    var pm = {
      object: object,
      objectSub: objectSub,
      table: table,
      title: title,
      bulk: true,
      noTrashBtn: true,
    };
    if (view.modal || !view.overview) pm.modal = true;
    detail(pm);
  };

  // sort
  table.sorter = function (row, noJump, noChangeCheck) {
    var sorted = false;
    var last = true;
    var sortId = table.sortId(row);
    var tr = table.tr[row.id];
    if (!sortId || !tr || !table.sort) return;
    var prevSortId = tr.attr('data-sort');
    if (sortId == prevSortId && !noChangeCheck) return;
    var sortDirection = 'asc';
    if (
      (isArray(table.sort) && str_starts_with(table.sort[0], '-')) ||
      str_starts_with(table.sort, '-')
    )
      sortDirection = 'desc';

    tr.attr('data-sort', sortId);
    if (tr.setBorderValue) tr.setBorderValue(row);
    tr.siblings('[data-sort]').each(function () {
      var sortIdCompare = $(this).attr('data-sort');
      var compare = sortId.localeCompare(sortIdCompare);
      if (
        (compare < 0 && sortDirection == 'asc') ||
        (compare > 0 && sortDirection == 'desc')
      ) {
        if (tr.next()[0] != $(this)[0]) {
          // shift day divider
          if (tr.hasClass('border')) tr.next().addClass('border');

          // shift one spot down
          tr.insertBefore(this);
          sorted = true;
          if ($(this).attr('data-last')) tr.attr('data-last', 1);

          // shift day divider
          if (tr.attr('data-border')) {
            var borderValue = tr.attr('data-border');
            if (tr.prev().attr('data-border') != borderValue)
              tr.addClass('border');
            else tr.removeClass('border');
            if (tr.next().attr('data-border') != borderValue)
              tr.next().addClass('border');
            else tr.next().removeClass('border');
          }
        }
        last = false;
        return false;
      }
    });
    if (last) tr.appendTo(element.tbody).attr('data-last', 1);
    if (tr.hasClass('active') && tr.jumpTo && sorted && !noJump) tr.jumpTo();
  };
  table.sortId = function (row) {
    if (!table.sort) return '';
    if (isString(table.sort)) table.sort = table.sort.split(',');
    var sortId = [];
    $.each(table.sort, function (i, key) {
      if (key.slice(0, 1) == '-') key = key.slice(1);
      var value = row[key];
      if (isArray(value)) return;
      else if (getObjectValue(fields, [object, key, 'reference']))
        value = getData(
          fields[object][key].reference,
          value,
          'short',
          fields[object][key].objectSub
        );
      sortId.push(value);
    });
    return sortId.join('_');
  };
  table.update = function () {
    element.remove();
    create({noRegister: true});
    table.loadData({refresh: true});
  };
  table.editColumns = function () {
    var value = table.tableFields;
    $.each(value, function (i, key) {
      if (str_starts_with(key, 'short')) value.splice(i, 1);
    });

    var pm = {
      title: 'Spaltenmanager',
      // fields: fields[object],
      fields: {},
      list: value,
      modal: false,
      save: function (value) {
        $.each(value, function (i, val) {
          if (str_starts_with(val, 'short')) value.splice(i, 1);
        });

        table.tableFields = value;
        if (param.overview) {
          localStorage.setItem(identGroup + '_tableFields', value);
          table.update();
        } else if (param.refObj) {
          var option = 'tableFields';
          if (param.tableFieldsSource) option = param.tableFieldsSource;
          fields[param.refObj][param.refKey][option] = value;
          ajax({
            post: true,
            object: 'setting',
            send: {
              object: param.refObj,
              key: param.refKey,
              option: option,
              value: value,
            },
            callback: function () {
              table.update();
            },
          });
        }
      },
    };
    $.each(fields[object], function (key) {
      var settings = getFieldSettings(param, key);
      pm.fields[key] = settings;
      if (settings && settings.percent)
        pm.fields[key + '_percent'] = {label: settings.label + ' (%)'};
    });
    sorter(pm);
  };

  if (view && view.overview)
    view.setContentHeight = function (height) {
      var viewportHeight = $(window).height();
      // calculate only on bigger screensizes
      if (viewportHeight >= 700) {
        cont.children('.table-scroll').css('max-height', height - 140);
      }
    };

  create();
  table.addData({data: ids, amount: param.total});
  return element;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function dataRef(value, object, row) {
  var key, refKey, refKey2;
  [key, refKey, refKey2] = value.split('.');
  var refObj = fields[object][key].reference;
  var id = row[key];
  var val = getData(refObj, id, refKey);
  if (refKey2) val = getData(fields[refObj][refKey].reference, val, refKey2); // 3. level
  return val;
}
